* {
    padding: 0px;
    margin: 0px;
  }
  
  p {
    padding: 0px;
    margin: 0px;
    ;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0px;
    margin: 0px;
    ;
  }
  
  figure {
    padding: 0px;
    margin: 0px;
  }
  
  ul {
    padding: 0px;
    margin: 0px;
    ;
  }
  
  ul li {
    padding: 0px;
    margin: 0px;
    ;
    list-style: none;
  }
  
  a {
    color: #000 !important;
    text-decoration: none !important;
    transition: all 0.3s;
  }
  
  a:hover {
    color: #000;
  }
  
  
  .dashboard_main {
    background-color: #E5E5E5;
  }
  
  .header {
    height: 200px !important;
    background-color: #7B4EE9;
  }
  
  .header .top_header .top_heading {
    padding-right: 15px;
  }
  
  .header .top_header {
    width: calc(100% - 400px);
    align-items: center;
    margin-left: auto;
    justify-content: space-between;
  }
  
  .header .top_header h1 {
    font-size: 34px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: #fff;
  }
  
  .menu_bar {
    background-color: #fff;
    padding: 40px 30px;
    border-radius: 15px;
    width: 380px;
    text-align: center;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
  }
  
  .menu_bar .profile_sec {
    margin-bottom: 25px;
  }
  
  .menu_bar .profile_sec figure {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 10px !important;
  }
  
  .menu_bar .profile_sec figure img {
    border-radius: 50%;
    width: 100%;
  }
  
  
  .menu_bar .profile_sec p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    color: #000;
  }
  
  
  
  
  
  
  
  
  .menu_bar ul li {
    border-radius: 50px;
  }
  
  .menu_bar ul li a {
    margin-bottom: 0px;
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    line-height: 28px;
    padding: 10px 30px;
    /* color: #000; */
    border-radius: 50px;
    display: flex;
    align-items: center;
  }
  
  .menu_bar ul li a span {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: inline-block;
    background-color: #fff;
    margin-right: 35px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .menu_bar ul li a.active {
    background: #7B4EE9;
    color: #fff !important;
  }
  
  .menu_bar ul li a.live_class {
    background: #FBF9FF;
  }
  
  .menu_bar ul li a:hover {
    background: #7B4EE9;
    color: #fff !important;
  }
  
  
  
  .menu_bar .logout {
    background: #7B4EE9;
    color: #fff !important;
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    padding: 15px 20px;
    border-radius: 13px;
    width: 100%;
    font-family: 'Inter', sans-serif;
    display: block;
    transition: all 0.3s;
  }
  
  .menu_bar .logout:hover {
    background-color: #000;
  }
  
  
  
  .menu_cont {
    justify-content: left;
    position: absolute;
    top: 10px;
    padding: 15px 15px;
    width: 100%;
  }
  
  .menu_cont .show_data {
    width: calc(100% - 380px);
    padding-top: 120px;
    padding-left: 15px;
  }
  
  .menu_cont .show_data .banner {
    margin-bottom: 40px;
  }
  
  .menu_cont .show_data .banner figure img {
    border-radius: 15px;
    width: 100%;
  }
  
  .menu_bar ul {
    margin-bottom: 100px;
  }
  
  
  
  .test_sec {
    justify-content: space-between;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .test_sec .test_cont {
    width: calc(50% - 20px);
    padding: 15px 15px;
    margin: 0px 10px;
    border-radius: 5px;
    box-shadow: 0px 1px 4px #ccc;
    background-color: #fff;
  }
  
  .test_sec .test_cont h2 {
    text-align: center;
    font-size: 26px;
    font-weight: 800;
    line-height: 30px;
    color: #000;
    font-family: 'Inter', sans-serif;
    margin-bottom: 20px;
  }
  
  .test_sec .test_cont figure img {
    width: 100%;
  }
  
  .test_sec .test_cont ul li {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #000;
    font-family: 'Inter', sans-serif;
  }
  
  .test_sec .test_cont ul li p {
    margin-bottom: 5px;
  }
  
  .test_sec .test_cont ul li span {
    text-align: left;
    margin-bottom: 5px;
    margin-right: 8px;
    max-width: 135px;
    min-width: 135px;
    display: inline-block;
  }
  
  .def_btnt {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    background: #7B4EE9;
    padding: 10px 30px;
    border-radius: 50px;
    transition: all 0.3s;
  }
  
  
  .def_btnt:hover {
    background: #000;
    color: #fff;
  }
  
  .show_btn {
    padding: 10px 0px;
    text-align: center;
  }
  
  
  @media only screen and (max-width:992px) {
    .menu_bar {
      padding: 30px 20px;
      width: 300px;
    }
  
    .menu_bar .profile_sec figure {
      height: 120px;
      width: 120px;
    }
  
    .menu_cont .show_data {
      width: calc(100% - 300px)
    }
  
    .header .top_header {
      width: calc(100% - 310px)
    }
  
    .header .top_header h1 {
      font-size: 26px;
    }
  
    .test_sec .test_cont h2 {
      font-size: 22px;
    }
  
    .menu_bar ul li a span {
      height: 30px;
      width: 30px;
      margin-right: 25px;
      padding: 15px;
    }
  
    .menu_bar ul li a span img {
      width: 15px;
    }
  
    .test_sec .test_cont ul li span {
      margin-right: 5px;
      max-width: 116px;
      min-width: 116px;
    }
  
    .test_sec .test_cont ul li {
      font-size: 14px;
    }
  
    .menu_bar ul li a {
      font-size: 18px;
      line-height: 22px;
      padding: 8px 20px;
    }
  
    .menu_bar .logout {
      font-size: 20px;
      line-height: 24px;
      padding: 12px 15px;
    }
  
  
  }
  
  @media only screen and (max-width:767px) {
  
    .menu_cont {
      position: inherit;
    }
  
    .header {
      height: 160px;
    }
  
    .header .top_header {
      width: 100%;
      margin: auto;
      flex-wrap: wrap;
    }
  
    .header .top_header .top_heading {
      width: 100%;
      padding: 10px 0px;
    }
  
  
    .header .top_header .top_heading h1 {
      text-align: center;
      font-size: 24px;
    }
  
  
  
  
  
    /*nav_start*/
  
    .menu_bar {
      z-index: 999;
      width: 80%;
      position: fixed;
      right: -100%;
      top: 0;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      margin: 0;
      border: 0;
      border-radius: 0;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100vh;
    }
  
    .menu_bar.visible {
      display: block;
      right: 0;
      -webkit-transition: right 0.4s ease;
      -moz-transition: right 0.4s ease;
      -ms-transition: right 0.4s ease;
      -o-transition: right 0.4s ease;
      transition: right 0.4s ease;
      height: 100vh;
    }
  
    .navbar-fostrap {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      margin: 0;
      position: absolute;
      top: 20px;
      right: 25px;
      padding: 0;
      z-index: 9999;
    }
  
    .navbar-fostrap span {
      height: 5px;
      background: #fff;
      margin: 5px 0;
      display: block;
      transition: 0.3s ease;
      width: 35px;
      border-radius: 5px;
    }
  
    .navbar-fostrap span:nth-child(1) {
      background: #fff;
    }
  
    .navbar-fostrap span:nth-child(2) {
      background: #fff;
      width: 70%;
      margin-left: auto;
    }
  
    .navbar-fostrap span:nth-child(3) {
      background: #fff;
      width: 90%;
      margin-left: auto;
    }
  
    .open_menu .navbar-fostrap span:nth-child(1) {
      transform: rotate(45deg);
      margin-top: 15px;
      background: #7B4EE9;
    }
  
    .open_menu .navbar-fostrap span:nth-child(3) {
      transform: rotate(-45deg);
      margin-top: -9px;
      background: #7B4EE9;
      width: 100%;
    }
  
    .open_menu .navbar-fostrap span:nth-child(2) {
      display: none;
    }
  
  
    /*nav_end*/
  
    .menu_bar ul li a {
      font-size: 16px;
      line-height: 20px;
      padding: 8px 15px;
    }
  
    .menu_bar ul li a span {
      border-radius: 50%;
      height: 25px;
      width: 25px;
      margin-right: 15px;
      padding: 10px;
    }
  
    .menu_bar ul {
      margin-bottom: 50px;
    }
  
  
    .menu_bar::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #F5F5F5;
    }
  
    .menu_bar::-webkit-scrollbar {
      width: 8px;
      background-color: #F5F5F5;
    }
  
    .menu_bar::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: #7B4EE9;
    }
  
    .menu_bar .logout {
      font-size: 18px;
      line-height: 24px;
      padding: 12px 15px;
    }
  
  
  
  
  
  
    .menu_bar ul li a span img {
      width: 15px;
    }
  
    .menu_bar {
      padding: 20px 15px;
    }
  
    .menu_bar .profile_sec {
      margin-bottom: 20px;
    }
  
    .menu_cont .show_data .banner {
      margin-bottom: 20px;
    }
  
    .logo a img {
      width: 100px;
    }
  
    .test_sec .test_cont ul li {
      font-size: 14px;
    }
  
    .test_sec .test_cont ul li span {
      margin-bottom: 5px;
      margin-right: 5px;
      max-width: 120px;
      min-width: 120px;
    }
  
    .test_sec .test_cont h2 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  
    .menu_cont .show_data {
      width: 100%;
      padding: 0px;
      margin-top: -44px;
    }
  
    .test_sec {
      flex-wrap: wrap;
    }
  
    .test_sec .test_cont {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .menu_bar .profile_sec figure {
      height: 100px;
      width: 100px;
    }
  
    .menu_bar .profile_sec figure img {
      width: 100%;
    }
  
  
  }
  
  
  .btn-primary {
    width: 125px;
    height: 33px;
    background: #7B4EE9;
    border-radius: 17px;
    border-width: 0px;
    color: white;
    margin-left: 30px;
  }
  
  /* Select Button*/
  .custom-select {
    border-width: 0.1px;
    border-radius: 20px;
    padding: 0 10px;
  }
  
  /* end select button */
  .result-area {
    width: 100%;
    height: 50vh;
    margin-top: 10px;
    border: 1px solid red
  }
  
  /* Login */
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }
  
  .login-area {
    background-color: #7B4EE9;
    height: 100vh;
  }
  
  label {
    margin-bottom: 0.5rem;
  }
  
  .login-box {
    width: 100%;
    text-align: center;
  }
  
  .login-logo {
    text-align: center !important;
  }
  
  
  .btn-block {
    display: block;
    width: 80%;
    background-color: #7B4EE9 !important;
    border-width: 0px !important;
  }
  
  .login-alert {
    margin: 10px;
  
  }
  
  .admin-assessment {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
  
  }
  
  .assmnt-h {
    width: fit-content;
  }
  
  .addnewcrs {
    margin-left: 38px;
  }
  
  .text-end {
    text-align: end;
  }
  
  .text-right {
    text-align: right;
  }
  
  .mr-15 {
    margin-right: 15px
  }
  .datePickerInput{
    padding: 5px 10px;
    width: 100%;
  }

  .loader-btn{
    margin: 0 auto;
    margin-bottom: 90px;
  }

  .ReactModal__Content--after-open{
    height: fit-content;
  }
  .modal-footer button{
    margin:5px
  }
  .modal-footer{
    margin-top:20px
  }
  .assmnt-h button{
   margin-left:20px
  }
  .loader-btn{
    margin-top:-14px
  }